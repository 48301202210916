<template>
  <div class="auth-wrapper auth-v2">
    <b-spinner style="width: 50px; height: 50px" class="spinner"  v-if="showSpinner"/>
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      
         <b-link
              class="brand-logo"
              to="/"
            >
              <span class="brand-logo">
                <b-img
                  src="@/assets/images/newLogo.png"
                  style="filter: invert(1);height: 100%;width: 166px;"
                  alt="logo"
                />
              </span>
          </b-link>
        <!-- <h2 class="brand-text text-primary ml-1">
          Vuexy
        </h2> -->
     
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        style="background: #000000;"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Welcome to SHELTER REGISTRY! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account and start the adventure
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="loginForm"
           
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                   
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{name:'auth-forgot-password'}">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                    
                      class="form-control-merge"
                      :type="passwordFieldType"
                    
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                  :value="true"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="showSpinner"
              >
                Sign in
              </b-button>
            </b-form>
          </validation-observer>

          <!-- <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{name:'auth-register'}">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text> -->

          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div> -->

          <!-- social buttons -->
          <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
        </b-col>
      </b-col>
        <!-- <b-col md="12" v-if="showSpinner" class="d-flex justify-content-center">
            <b-spinner style="width: 50px; height: 50px" />
        </b-col> -->
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { mapGetters, mapActions } from "vuex";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {dbCollections} from "@/utils/firebaseCollections";
import firebase from '@/utils/firebaseInit.js';

const db = firebase.firestore();
export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      showSpinner:false,
      // validation rules
      required: false,
      email,
    }
  },
  created() {
    var self = this;

    let user = JSON.parse(localStorage.getItem("rememberUser"));

    if (user !== null) {
      self.userEmail = user.email;
      self.password = user.password;

      self.status = true;
    }
  },

  computed: {
    ...mapGetters({idSpecificUserArray:"user/idSpecificUser"}),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
     ...mapActions({idSpecificUser:"user/idSpecificUserData"}),
    login() {
      var self = this;
      self.showSpinner = true;
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          // debugger // eslint-disable-line
          db.collection(dbCollections.USERS).where("email","==",self.userEmail).get().then((admin) => {
            if(admin.empty){
              self.showSpinner = false;
              self.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: "Admin Not Found.",
                  variant: 'danger',
                },
              })
              return;
            }
            if (admin.docs[0].data().userRole && admin.docs[0].data().userRole == 'admin') {     
              firebase.auth().signInWithEmailAndPassword(self.userEmail, self.password)
              .then((userDoc) => {
                  self.idSpecificUser(userDoc.user.uid).then(() => {
                  if(self.status) {
                    localStorage.setItem("rememberUser", JSON.stringify({email: self.userEmail, password: self.password}));
                  } else {
                    localStorage.removeItem("rememberUser");
                  }
    
                  var tempObj = {};
                  tempObj = {
                    id: userDoc.user.uid,
                    fullName: `${self.idSpecificUserArray[0].firstName} ${self.idSpecificUserArray[0].lastName}`,
                    username: `${self.idSpecificUserArray[0].firstName} ${self.idSpecificUserArray[0].lastName}`,
                    email: self.userEmail,
                    role: 'admin',
                    password: self.password,
                    // eslint-disable-next-line global-require
                    avatar:self.idSpecificUserArray[0].profilePicture ? self.idSpecificUserArray[0].profilePicture : 'https://firebasestorage.googleapis.com/v0/b/erfolg-16de9.appspot.com/o/DefaultUser%2FDefaultUser.png?alt=media&token=1b92ac0f-1395-4062-9aa2-59f126ccf5e9',
                    ability: [
                      {
                        action: 'manage',
                        subject: 'all',
                      },
                    ],
                    extras: {
                      eCommerceCartItemsCount: 5,
                    }
                  };
                  localStorage.setItem('currentUser', JSON.stringify(tempObj)) 
    
                  useJwt.login({
                    email: self.userEmail,
                    password: self.password,
                  })
                  .then(response => {
                    const { userData } = response.data
                    useJwt.setToken(response.data.accessToken)
                    useJwt.setRefreshToken(response.data.refreshToken)
                    localStorage.setItem('userData', JSON.stringify(userData))
                    self.$ability.update(userData.ability)
    
                    // ? self is just for demo purpose as well.
                    // ? Because we are showing eCommerce app's cart items count in navbar
                    self.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)
    
                    // ? self is just for demo purpose. Don't think CASL is role based in self case, we used role in if condition just for ease
                    self.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                    .then(() => {
                      self.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `Welcome ${userData.fullName || userData.username}`,
                          icon: 'CoffeeIcon',
                          variant: 'success',
                          text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                        },
                      })
                    }).catch((error) => {
                       self.showSpinner = false;
                       console.log('ERROR in jwt login',error)
                    })
                  })
                  .catch(error => {
                    self.showSpinner = false;
                    self.$refs.loginForm.setErrors(error.response.data.error)
                  })
                  })
              })
              .catch((error) => {
                self.showSpinner = false;
                console.error(error);
                self.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Error`,
                    variant: 'danger',
                    text: 'Something went wrong. May be you entered incorrect credentials. Please try again.',
                  },
                })
              })
            } else {
               self.showSpinner = false;
              self.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: "Admin Not Found.",
                  variant: 'danger',
                },
              })
            }
          });
        }else{
           self.showSpinner = false;
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.spinner {
  z-index: 999;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}
</style>
